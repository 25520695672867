// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-businesses-how-to-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/businesses/how-to.jsx" /* webpackChunkName: "component---src-pages-businesses-how-to-jsx" */),
  "component---src-pages-businesses-index-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/businesses/index.jsx" /* webpackChunkName: "component---src-pages-businesses-index-jsx" */),
  "component---src-pages-businesses-savings-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/businesses/savings.jsx" /* webpackChunkName: "component---src-pages-businesses-savings-jsx" */),
  "component---src-pages-businesses-security-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/businesses/security.jsx" /* webpackChunkName: "component---src-pages-businesses-security-jsx" */),
  "component---src-pages-businesses-support-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/businesses/support.jsx" /* webpackChunkName: "component---src-pages-businesses-support-jsx" */),
  "component---src-pages-commuters-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/commuters.jsx" /* webpackChunkName: "component---src-pages-commuters-jsx" */),
  "component---src-pages-confirm-delivery-js": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/confirm-delivery.js" /* webpackChunkName: "component---src-pages-confirm-delivery-js" */),
  "component---src-pages-contact-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-delivery-confirmation-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/delivery-confirmation.jsx" /* webpackChunkName: "component---src-pages-delivery-confirmation-jsx" */),
  "component---src-pages-delivery-confirmed-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/delivery-confirmed.jsx" /* webpackChunkName: "component---src-pages-delivery-confirmed-jsx" */),
  "component---src-pages-faq-business-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/faq/business.jsx" /* webpackChunkName: "component---src-pages-faq-business-jsx" */),
  "component---src-pages-faq-commuters-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/faq/commuters.jsx" /* webpackChunkName: "component---src-pages-faq-commuters-jsx" */),
  "component---src-pages-faq-general-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/faq/general.jsx" /* webpackChunkName: "component---src-pages-faq-general-jsx" */),
  "component---src-pages-faq-index-js": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/faq/index.js" /* webpackChunkName: "component---src-pages-faq-index-js" */),
  "component---src-pages-how-it-works-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/how-it-works.jsx" /* webpackChunkName: "component---src-pages-how-it-works-jsx" */),
  "component---src-pages-index-js": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-investors-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/investors.jsx" /* webpackChunkName: "component---src-pages-investors-jsx" */),
  "component---src-pages-legal-guarantee-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/legal/guarantee.jsx" /* webpackChunkName: "component---src-pages-legal-guarantee-jsx" */),
  "component---src-pages-legal-index-js": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/legal/index.js" /* webpackChunkName: "component---src-pages-legal-index-js" */),
  "component---src-pages-legal-insurance-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/legal/insurance.jsx" /* webpackChunkName: "component---src-pages-legal-insurance-jsx" */),
  "component---src-pages-legal-payment-terms-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/legal/payment-terms.jsx" /* webpackChunkName: "component---src-pages-legal-payment-terms-jsx" */),
  "component---src-pages-legal-privacy-policy-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/legal/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-jsx" */),
  "component---src-pages-legal-terms-of-service-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/legal/terms-of-service.jsx" /* webpackChunkName: "component---src-pages-legal-terms-of-service-jsx" */),
  "component---src-pages-lock-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/lock.jsx" /* webpackChunkName: "component---src-pages-lock-jsx" */),
  "component---src-pages-services-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-vision-jsx": () => import("/Users/kakchan/Documents/workspace/handcarry/handcarry-website/src/pages/vision.jsx" /* webpackChunkName: "component---src-pages-vision-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/kakchan/Documents/workspace/handcarry/handcarry-website/.cache/data.json")

