import { merge } from 'ramda';
import {
  RECIPIENT_CONFIRM_DELIVERY, RECIPIENT_CONFIRM_DELIVERY_FAILED,
  RECIPIENT_CONFIRM_DELIVERY_SUCCESS,
  GET_DELIVERY_BY_UUID,
  GET_DELIVERY_BY_UUID_SUCCESS,
} from './actions'

const initialState = {
  isFetching: false,
  isConfirming: false,
  list: [],
};

export const deliveries = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DELIVERY_BY_UUID: {
      return merge(state, { isFetching: true });
    }
    case GET_DELIVERY_BY_UUID_SUCCESS: {
      console.log(payload);
      const { delivery } = payload;
      const { uuid } = delivery;
      return merge(state, {
        isFetching: false,
        list: state
          .list
          .filter(item => item.uuid !== uuid)
          .concat(delivery),
      });
    }
    case RECIPIENT_CONFIRM_DELIVERY: {
      return merge(state, {
        isConfirming: true,
      });
    }
    case RECIPIENT_CONFIRM_DELIVERY_SUCCESS:
    case RECIPIENT_CONFIRM_DELIVERY_FAILED:
      return merge(state, {
        isConfirming: false,
      });
    default:
      return state;
  }
};