import ApolloClient from 'apollo-boost';
/*
import { Auth } from 'aws-amplify';
*/
import { CONFIG } from '../config';

const AwsGraphqlFetch = async (uri, options) => {
/*
  const jwtToken = (await Auth.currentSession() || CONFIG.tmpJWT).getIdToken().getJwtToken();
  const newOptions = Object.assign({}, options);
  newOptions.headers.Authorization = jwtToken;
  console.log('Request with auth header', jwtToken);
  console.log('options', newOptions);
*/
  const newOptions = options || {};
  return fetch(uri, newOptions);
};

console.log('HANDCARRY_SERVICE_URL', CONFIG.HANDCARRY_SERVICE_URL);

const client = new ApolloClient({
  uri: CONFIG.HANDCARRY_SERVICE_URL,
  fetch: AwsGraphqlFetch,
});

const GraphQLHelper = {};

GraphQLHelper.client = client;
GraphQLHelper.query = (query, variables) => client.query({ query, variables });
GraphQLHelper.mutate = (mutation, variables) => client.mutate({ mutation, variables });

export { GraphQLHelper };
