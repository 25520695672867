import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import { createStore as reduxCreateStore, combineReducers, applyMiddleware } from 'redux';
import { modal } from './modal/reducer';
import { deliveries } from './deliveries/reducer';
import { hydrateState } from './hydrateState';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const appliedMiddleware = applyMiddleware(sagaMiddleware);

const createStore = () => reduxCreateStore(
  combineReducers({
    modal,
    deliveries,
  }),
  hydrateState(),
  composeWithDevTools(
    appliedMiddleware,
  ),
);

const store = createStore();

sagaMiddleware.run(sagas);

export { store };