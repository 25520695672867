import { ac } from '../actions';

export const GET_DELIVERY_BY_UUID = 'GET_DELIVERY_BY_UUID';
export const GET_DELIVERY_BY_UUID_SUCCESS = 'GET_DELIVERY_BY_UUID_SUCCESS';
export const GET_DELIVERY_BY_UUID_FAILED = 'GET_DELIVERY_BY_UUID_FAILED';
export const RECIPIENT_CONFIRM_DELIVERY = 'RECIPIENT_CONFIRM_DELIVERY';
export const RECIPIENT_CONFIRM_DELIVERY_SUCCESS = 'RECIPIENT_CONFIRM_DELIVERY_SUCCESS';
export const RECIPIENT_CONFIRM_DELIVERY_FAILED = 'RECIPIENT_CONFIRM_DELIVERY_FAILED';

export const getDeliveryByUuid = uuid => ac(GET_DELIVERY_BY_UUID, { uuid });
export const recipientConfirmDelivery = (uuid, { data, resolve }) =>
  ac(RECIPIENT_CONFIRM_DELIVERY, { uuid, data, resolve });