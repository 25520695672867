import { withReactProvider } from './src/components/shared/hoc/withReactProvider'

/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
export const shouldUpdateScroll = ({
  prevRouterProps,
  pathname,
}) => {
  if (prevRouterProps) {
    const { location: { pathname: prevPathname } } = prevRouterProps;
    const nonScrollPathReg = /^\/(faq|legal)/;
    if (nonScrollPathReg.test(pathname) && nonScrollPathReg.test(prevPathname)) {
      return false;
    }
  }
  return true;
};

export const wrapRootElement = withReactProvider;