import { SHOW_MODAL, HIDE_MODAL } from './actions';

const initialState = {
  name: '',
  data: {},
};

export const modal = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_MODAL:
      return {
        name: payload.name,
        data: payload.data || initialState.data,
      };
    case HIDE_MODAL:
      return initialState;
    default:
      return state;
  }
};
