import gql from 'graphql-tag';

export const getDeliveryByUuidQuery = gql`
  query GetDeliveryByUuid($uuid: String!) {
    getDeliveryByUuid(uuid: $uuid) {
        delivery {
            uuid
            price
            currency
            distance
            weight
            status
            parcelId
            submittedAt
            imageUrls
            size
            from {
                firstName
                lastName
                postcode
                prefecture
                city
                street
                building
                name
                latitude
                longitude
                email
                phone
            }
            to {
                firstName
                lastName
                postcode
                prefecture
                city
                street
                building
                name
                latitude
                longitude
                email
                phone
            }
        }
    }
  }
`;