import { takeLatest, put } from 'redux-saga/effects';
import { ac } from '../actions';
import {
  RECIPIENT_CONFIRM_DELIVERY,
  RECIPIENT_CONFIRM_DELIVERY_FAILED,
  RECIPIENT_CONFIRM_DELIVERY_SUCCESS,
  GET_DELIVERY_BY_UUID,
  GET_DELIVERY_BY_UUID_FAILED,
  GET_DELIVERY_BY_UUID_SUCCESS,
} from './actions';
import { GraphQLHelper } from '../../helpers/GraphQLHelper';
import { getDeliveryByUuidQuery } from './graphql/getDeliveryByUuid.graphql';
import { recipientConfirmDeliveryMutation } from './graphql/recipientConfirmDelivery.graphql';

function* performRecipientConfirmDelivery({ payload: { uuid, data, resolve }}) {
  try {
    const {
      data: {
        recipientConfirmDelivery,
      },
    } = yield GraphQLHelper.mutate(recipientConfirmDeliveryMutation, {
      uuid,
      data,
    });
    if (!recipientConfirmDelivery) {
      throw new Error('Confirm delivery failed');
    }
    if (resolve) { resolve(); }
    yield put(ac(RECIPIENT_CONFIRM_DELIVERY_SUCCESS));
  } catch (ex) {
    console.log('Error performRecipientConfirmDelivery():', ex);
    yield put(ac(RECIPIENT_CONFIRM_DELIVERY_FAILED, ex));
  }
}

function* performGetDeliveryByUuid({ payload: { uuid } }) {
  try {
    const {
      data: {
        getDeliveryByUuid: { delivery },
      },
    } = yield GraphQLHelper.query(getDeliveryByUuidQuery, {
      uuid,
    });
    yield put(ac(GET_DELIVERY_BY_UUID_SUCCESS, { delivery }));

  } catch (ex) {
    console.log('Error performGetDeliveryByUuid():', ex);
    yield put(ac(GET_DELIVERY_BY_UUID_FAILED, ex));
  }
}

/* Watchers */
function* watchGetDeliveryByUuid() {
  yield takeLatest(GET_DELIVERY_BY_UUID, performGetDeliveryByUuid);
}

function* watchRecipientConfirmDelivery() {
  yield takeLatest(RECIPIENT_CONFIRM_DELIVERY, performRecipientConfirmDelivery);
}

export default [
  watchGetDeliveryByUuid(),
  watchRecipientConfirmDelivery(),
];